<template>
    <div>
        <page-title title="menu.brands"></page-title>
        <brand-table ref="table">
            <template #actions>
                <create-button
                    :route-name="routeType.BRAND_CREATE"
                ></create-button>
            </template>
        </brand-table>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PageTitle from '@/components/layout/components/pageTitle.vue'
import CreateButton from '@/components/form/Actions/CreateButton.vue'
import BrandTable from './parts/BrandTable.vue'

export default {
    components: {
        PageTitle,
        CreateButton,
        BrandTable,
    },
    data: () => ({
        routeType,
    }),
}
</script>
