export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    width: 60,
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                },
                {
                    value: 'logo',
                    text: this.$t('labels.logo'),
                },
                {
                    value: 'tags',
                    text: this.$t('labels.tags'),
                },
            ]
        },
    },
}
