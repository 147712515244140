var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"py-0 pr-1 flex-shrink-1",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('v-col',{staticClass:"py-0 pl-1 hover-unhide",attrs:{"cols":"auto"}},[_c('edit-button',{attrs:{"tooltip":_vm.$t('labels.copy_frontend_url'),"icon":"content_copy","xsmall":""},on:{"execute":function($event){return _vm.copyBrandFElink(item)}}})],1)],1)]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.logo.public_url,"height":"100","width":"100","contain":""}})]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }