import Config from '@/config.loader'

const FeBaseURL = Config.getConfigValue('VUE_APP_PUBLIC_FE_BASE')

export function copyBrandFElink(brand) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(`${FeBaseURL}/brand/${brand.slug}`)
    }
}

export default {
    FeBaseURL,
    copyBrandFElink,
}
