import cloneDeep from 'lodash/cloneDeep'
import Notification from '@/services/Notification/Notification'
import DataIterator from '@/components/table/DataIterator.vue'
import DTable from '@/components/table/DTable.vue'
import EditButton from '@/components/table/buttons/EditButton.vue'
import DeleteAction from '@/components/table/actions/DeleteAction.vue'
import DeleteBatchAction from '@/components/table/batchActions/DeleteBatchAction.vue'
import TableFilters from '@/components/filter/TableFilters.vue'

export default {
    components: {
        DataIterator,
        DTable,
        EditButton,
        DeleteAction,
        DeleteBatchAction,
        TableFilters,
    },

    props: {
        selectedFilters: Object,
        selectedItems: Array,
        fixedFilters: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data: () => ({
        filters: {},
        selectedIds: [],
    }),

    methods: {
        onRowSelected(selectedIds) {
            this.selectedIds = selectedIds
            this.$emit('update:selectedItems', selectedIds)
        },
        onEntityDeleted(entityName) {
            Notification.entityDeleted(this.tableName, entityName)
            this.updateTableItems()
        },
        onEntityDeleteFailed(entityName) {
            Notification.deleteError(entityName)
            this.updateTableItems()
        },
        onBatchDeleteFinishedWithErrors(result) {
            Notification.batchDeleteFinishedWithErrors(result)
            this.updateTableItems()
        },
        onBatchDeleteFinished(result) {
            Notification.batchDeleteFinished(result)
            this.updateTableItems()
        },
        onBatchDeleteCanceled(result) {
            Notification.batchDeleteCanceled(result)
            this.updateTableItems()
        },
        updateTableItems() {
            this.$refs.iterator.updateItems(true)
        },
        updateFilters(filters) {
            filters = cloneDeep(filters)
            delete filters.itemsPerPage
            delete filters.page
            delete filters.order
            this.$emit('update:selectedFilters', {
                ...this.fixedFilters,
                ...filters,
            })
        },
    },
}
